<script>
/**
 * Master view for milestone management
 *
 * @event planning-refresh -> triggers fetchData and "silent" reload of underlying board.
 */
import PlanningBoard from "@/components/Planning/PlanningBoard";
import ProjectStageService from "@/services/ProjectStageService";
import ProjectService from "@/services/ProjectService";
import { EventBus } from "@/libraries/EventBus";

export default {
    props: {
        project_id: {}
    },
    components: { PlanningBoard },
    data() {
        return {
            loading: true,
            board: [],
            project: {}
        };
    },
    async created() {
        this.loading = true;
        await this.fetchProject();
        await this.fetchData();
        EventBus.$on("planning-refresh", this.fetchData);
    },
    beforeDestroy() {
        EventBus.$off("planning-refresh", this.fetchData);
    },
    methods: {
        async fetchData() {
            try {
                const { data } = await ProjectStageService.GET_board(
                    this.project_id
                );
                this.board = data.data;
            } catch (e) {
                console.log("fe e", e);
            }
            this.loading = false;
        },
        async fetchProject() {
            try {
                this.loading = true;
                const r = await ProjectService.GETS(this.project_id);
                this.project = r.data.data;
            } catch (e) {
                console.log("Faker", e);
            }
            this.loading = false;
        }
    }
};
</script>

<template>
    <div class="v-planning v-planning-milestones mt-4">
        <div class="container-fluid">
            <v-loader :loading="loading" v-if="loading" />
            <template v-else-if="board.length > 0">
                <div class="row card-wrapper">
                    <div class="col-12">
                        <planning-board
                            :project="project"
                            :board="board"
                            :project-id="project_id"
                        />
                    </div>
                </div>
            </template>
            <div class="alert alert-warning" v-else>{{ $t('milestones.no_data_found') }}</div>
        </div>
    </div>
</template>
