<script>
import VStage from "./VStage";
import { EventBus } from "@/libraries/EventBus";
import { mapGetters } from "vuex";

export default {
    props: {
        board: {},
        project: {},
        projectId: {}
    },
    components: { VStage },
    data() {
        return {
            showItems: false
        };
    },
    computed: {
        ...mapGetters("auth", ["getIsAdmin"])
    },
    methods: {
        triggerShow(newShow) {
            this.showItems = newShow;
        },
        eventize(type) {
            EventBus.$emit("milestones-board", { action: type });
        }
    }
};
</script>

<template>
    <div class="v-planning-board">
        <div class="">
            <card>
                <div class="d-flex align-items-center justify-content-between">
                    <div>
                        <base-button
                            @click="eventize('open')"
                            type="primary"
                            size="sm"
                            ><i class="far fa-plus"></i>
                            {{ $t("milestones.expand_all") }}</base-button
                        ><base-button
                            @click="eventize('close')"
                            type="primary"
                            size="sm"
                            ><i class="far fa-minus"></i>
                            {{ $t("milestones.fold_all") }}</base-button
                        >
                    </div>
                    <div>
                        <span style="font-size: 11px"
                            ><i class="far fa-pencil-ruler mr-1"></i>
                            {{ project.planning_type.name }}</span
                        >
                    </div>
                </div>
            </card>
        </div>
        <div class="planning-board">
            <div
                v-for="(stage, stageIdent) in board"
                :key="stage.id"
                class="vp-container"
            >
                <v-stage :stage="stage" />
                <template
                    v-if="project.planning_state === 0 && stageIdent === 0"
                >
                    <div class="text-center alert alert-info p-1">
                        {{
                            $t("milestones.notice_this_is_a_screening_project")
                        }}
                    </div></template
                >
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.v-planning-board {
    .planning-board {
        .vp-container {
            margin-bottom: 20px;
        }
    }
}
</style>
